/* global BigInt */

import BaseWaitingModal from "@components/base/BaseWaitingModal";
import ScrollToTop from "@components/ScrollToTop";
import LoadingIndicator from "@layouts/LoadingIndicator";
import "antd/dist/antd.min.css";

import { ThemeProvider } from "@mui/material/styles";
import { theme } from "@src/styles/themes";
import i18next from "i18next";
import "mainam-react-native-string-utils";
import React, { createRef, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { WagmiConfig } from "wagmi";
import "./assets/Bai_Jamjuree/BaiJamjuree-Bold.ttf";
import { wagmiConfig } from "./config";
import "./index.scss";
import App from "./pages";
import stores from "./redux";
import { GlobalStyle } from "./styles/global";
import "./utils/global-utils";
// try {
//   setTimeout((_) => {
//     const ga4react = new GA4React("G-2ESCWMS432");
//     ga4react.initialize().catch((err) => console.error(err));
//   }, 4000);
// } catch (err) {
//   console.error(err);
// }
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
});
const root = createRoot(document.getElementById("root"));
export const refLoading = createRef();
export const LoadingRef = createRef();

const Root = () => {
  const [ready, setReady] = useState(false);

  useEffect(() => {
    setReady(true);
    if (["wallet.ivirse.com", "localhost"].includes(global.location.hostname)) {
      window.location.href = "https://aiomt.ivirse.com";
    }
  }, []);
  return (
    <Provider store={stores}>
      <BrowserRouter>
        <I18nextProvider>
          <ScrollToTop />
          {ready ? (
            <WagmiConfig config={wagmiConfig}>
              <ThemeProvider theme={theme}>
                <App />
              </ThemeProvider>
              <GlobalStyle />
            </WagmiConfig>
          ) : null}
          <LoadingIndicator ref={refLoading} />
          <BaseWaitingModal ref={LoadingRef} destroyOnClose />
        </I18nextProvider>
      </BrowserRouter>
    </Provider>
  );
};

root.render(<Root />);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
